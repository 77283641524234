<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SalonsStaff',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
